import { useEffect } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';
import StateIcon from 'components/icons/StateIcon';
import getTextColorStyles from '@activebrands/core-web/utils/style-functions/get-text-color-styles';

const Wrapper = styled('div', {
    position: 'relative',
    zIndex: 1,
    width: '95vw',
    height: 'auto',
    overflow: 'hidden',
    backgroundColor: 'var(--color-bg)',

    [media.min['mobile.lg']]: {
        width: '600px',
    },
});

const CloseButton = styled('button', {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: '0',
    top: '0',
    gap: '8px',
    height: '48px',
    paddingRight: '16px',
    zIndex: 2,
});

const PopupOverlay = ({ callback, closeButtonColor, content, cookieLifespan, id }) => {
    // Add cookie or session storage when popup is closed
    useEffect(() => {
        return () => {
            callback(id, cookieLifespan);
        };
    }, []);

    return (
        <Wrapper id={id}>
            <CloseButton $style={getTextColorStyles(closeButtonColor)} onClick={() => overlay.close(id)}>
                <span>{fm('Close')}</span>
                <StateIcon color="currentColor" size="24px" state="reset" />
            </CloseButton>
            <CmsDataTransformerBlock isPopup block={content?.[0]} />
        </Wrapper>
    );
};

PopupOverlay.propTypes = {
    callback: PropTypes.func,
    closeButtonColor: PropTypes.string,
    content: PropTypes.any,
    cookieLifespan: PropTypes.string,
    id: PropTypes.string,
};

export default PopupOverlay;
